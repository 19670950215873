exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-e-2-log-js": () => import("./../../../src/pages/case-studies/e2log.js" /* webpackChunkName: "component---src-pages-case-studies-e-2-log-js" */),
  "component---src-pages-case-studies-hegde-hospital-js": () => import("./../../../src/pages/case-studies/hegde-hospital.js" /* webpackChunkName: "component---src-pages-case-studies-hegde-hospital-js" */),
  "component---src-pages-case-studies-inspirage-js": () => import("./../../../src/pages/case-studies/inspirage.js" /* webpackChunkName: "component---src-pages-case-studies-inspirage-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-sistic-js": () => import("./../../../src/pages/case-studies/sistic.js" /* webpackChunkName: "component---src-pages-case-studies-sistic-js" */),
  "component---src-pages-case-study-details-js": () => import("./../../../src/pages/caseStudyDetails.js" /* webpackChunkName: "component---src-pages-case-study-details-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dynamind-cs-details-js": () => import("./../../../src/pages/dynamindCsDetails.js" /* webpackChunkName: "component---src-pages-dynamind-cs-details-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/homePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-oautomate-js": () => import("./../../../src/pages/products/oautomate.js" /* webpackChunkName: "component---src-pages-products-oautomate-js" */),
  "component---src-pages-products-thrymros-js": () => import("./../../../src/pages/products/thrymros.js" /* webpackChunkName: "component---src-pages-products-thrymros-js" */),
  "component---src-pages-products-workflow-js": () => import("./../../../src/pages/products/workflow.js" /* webpackChunkName: "component---src-pages-products-workflow-js" */),
  "component---src-pages-rpa-page-js": () => import("./../../../src/pages/rpaPage.js" /* webpackChunkName: "component---src-pages-rpa-page-js" */),
  "component---src-pages-services-ai-ml-js": () => import("./../../../src/pages/services/ai-ml.js" /* webpackChunkName: "component---src-pages-services-ai-ml-js" */),
  "component---src-pages-services-api-development-js": () => import("./../../../src/pages/services/api-development.js" /* webpackChunkName: "component---src-pages-services-api-development-js" */),
  "component---src-pages-services-block-chain-js": () => import("./../../../src/pages/services/block-chain.js" /* webpackChunkName: "component---src-pages-services-block-chain-js" */),
  "component---src-pages-services-cloud-computing-js": () => import("./../../../src/pages/services/cloud-computing.js" /* webpackChunkName: "component---src-pages-services-cloud-computing-js" */),
  "component---src-pages-services-data-analysis-js": () => import("./../../../src/pages/services/data-analysis.js" /* webpackChunkName: "component---src-pages-services-data-analysis-js" */),
  "component---src-pages-services-data-engineering-js": () => import("./../../../src/pages/services/data-engineering.js" /* webpackChunkName: "component---src-pages-services-data-engineering-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-development-teams-js": () => import("./../../../src/pages/services/development-teams.js" /* webpackChunkName: "component---src-pages-services-development-teams-js" */),
  "component---src-pages-services-frontend-development-js": () => import("./../../../src/pages/services/frontend-development.js" /* webpackChunkName: "component---src-pages-services-frontend-development-js" */),
  "component---src-pages-services-managed-services-js": () => import("./../../../src/pages/services/managed-services.js" /* webpackChunkName: "component---src-pages-services-managed-services-js" */),
  "component---src-pages-services-mobile-applications-js": () => import("./../../../src/pages/services/mobile-applications.js" /* webpackChunkName: "component---src-pages-services-mobile-applications-js" */),
  "component---src-pages-services-quality-assurance-js": () => import("./../../../src/pages/services/quality-assurance.js" /* webpackChunkName: "component---src-pages-services-quality-assurance-js" */),
  "component---src-pages-services-test-automation-js": () => import("./../../../src/pages/services/test-automation.js" /* webpackChunkName: "component---src-pages-services-test-automation-js" */),
  "component---src-pages-services-trained-resourcing-services-js": () => import("./../../../src/pages/services/trained-resourcing-services.js" /* webpackChunkName: "component---src-pages-services-trained-resourcing-services-js" */),
  "component---src-pages-services-ui-ux-services-js": () => import("./../../../src/pages/services/ui-ux-services.js" /* webpackChunkName: "component---src-pages-services-ui-ux-services-js" */),
  "component---src-pages-services-web-applications-js": () => import("./../../../src/pages/services/web-applications.js" /* webpackChunkName: "component---src-pages-services-web-applications-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

